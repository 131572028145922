const phoneFormat = (phoneNumber) => {
    if(!phoneNumber)
        return phoneNumber;

    if(phoneNumber.length > 15)
        return phoneNumber.substring(0, 15)

    const match = phoneNumber
        .replace(/\D+/g, '').replace(/^1/, '')
        .match(/([^\d]*\d[^\d]*){1,10}$/)[0]

    const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
    const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
    const part3 = match.length > 6 ? ` ${match.substring(6, 8)}` : ''
    const part4 = match.length > 8 ? ` ${match.substring(8, 10)}` : ''
    return `${part1}${part2}${part3}${part4}`
}

const intlPhoneFormat = (code, phoneNumber) => {
    const num = phoneFormat(phoneNumber);
    return `+${code} ${num}`
}

const clearPhoneFormat = (phoneNumber) => {
    return phoneNumber
        .replaceAll(')', '')
        .replaceAll('(', '')
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .trim();
}

const PhoneUtil = {
    phoneFormat,
    intlPhoneFormat,
    clearPhoneFormat
}

export default PhoneUtil;