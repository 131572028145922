import {Button, ConfigProvider, Form, Input} from "antd";
import {useEffect, useRef, useState} from "react";
import {t} from "i18next";
import RoleSelectBox from "../role/RoleSelectBox";
import PermissionGroup from "../role/PermissionGroup";
import ApiService from "../../core/service/api-service";
import {Pages} from "../../AppRouter";
import {useHistory} from "react-router-dom";
import { Tabs } from 'antd';
import Flex from "../../core/components/Flex";
import FileUtils from "../../core/utils/file-utils";
import InstitutionSelect from "./InstitutionSelect";
import CountryPhoneInput from "antd-country-phone-input";
import PhoneUtil from "../../core/utils/phone-util";
import en from 'world_countries_lists/data/countries/en/world.json';

const { TabPane } = Tabs;
export default function UserCreateEdit({userId})
{
    const history = useHistory();
    const formRef = useRef();
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [disabledPermissions, setDisabledPermissions] = useState([])

    const [user, setUser] = useState({permissionIdList: []});

    const [signImage, setSignImage] = useState('');

    const save = async (form) => {
        if(userId)
        {
            const response = await ApiService.UserManager.EditUser(userId, {
                ...form,
                permissionIdList: selectedPermissions,
                doctorSignPicture: signImage.data,
                phone: `${form.phone.code}-${PhoneUtil.clearPhoneFormat(form.phone.phone)}`,
            })
        }
        else
        {
            const response = await ApiService.UserManager.CreateUser({
                ...form,
                permissionIdList: selectedPermissions,
                doctorSignPicture: signImage.data,
                phone: `${form.phone.code}-${PhoneUtil.clearPhoneFormat(form.phone.phone)}`,
            })

            if(response.success)
            {
                history.push(Pages.User.Edit(response.data))
            }
        }
    }

    const getUser = async () => {
        if(userId)
        {
            const response = await ApiService.UserManager.GetUser(userId);
            if(response.success)
            {
                setSelectedPermissions(response.data.permissionIdList)
                setDisabledPermissions(response.data.role?.permissionIdList)

                formRef.current.setFieldsValue({
                    ...response.data,
                    roleId: response.data.role.id,
                    ...response.data.userInformation,
                    phone: {
                        code: parseInt(response.data.phone?.split('-')[0]),
                        phone: PhoneUtil.phoneFormat(response.data.phone?.split('-')[1])
                    },
                })

                const image = await ApiService.UserManager.GetDoctorSignImage(userId);

                setSignImage({
                    data: null,
                    url: image?.data
                })
            }
        }
    }

    const selectImage = async () => {
        const file = await FileUtils.getFileAsBase64({accept: ''});
        setSignImage(file)
    }

    const onValuesChange = (field, formData) => {
        formRef.current.setFieldsValue({
            ...formData,
            phone: {
                code: formData.phone.code || 90,
                phone: PhoneUtil.phoneFormat(formData.phone.phone)
            }
        })
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <>
            <ConfigProvider locale={en}>
                <Form
                    size={'small'}
                    layout={'vertical'}
                    ref={formRef}
                    onFinish={save}
                    onValuesChange={onValuesChange}
                    initialValues={{
                        phone: {
                            code: 90
                        }
                    }}
                >

                    <Form.Item
                        label={t('Username')}
                        name={'username'}
                        rules={[
                            {type: "regexp", pattern: new RegExp("^[0-9]*$"),}
                        ]}
                    >
                        <Input disabled={userId} />
                    </Form.Item>

                    <Form.Item
                        label={t('Email')}
                        name={'email'}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Phone')}
                        name="phone"
                    >
                        <CountryPhoneInput />
                    </Form.Item>

                    <Form.Item
                        label={t('Name')}
                        name={'name'}
                        rules={[{required: true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Visible Name')}
                        name={'visibleName'}
                        rules={[{required: true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Password')}
                        name={'password'}
                        rules={[{required: userId ? false : true}]}
                    >
                        <Input/>
                    </Form.Item>

                    <InstitutionSelect
                        formItem={{
                            name: 'institutionIdList',
                            label: t('Institutions')
                        }}
                        hide={true}
                    />

                    <div style={{padding: 8, border: '1px solid #e0e0e0'}}>
                        <Form.Item
                            label={t('Role')}
                            name={'roleId'}
                            rules={[{required: true}]}
                        >
                            <RoleSelectBox
                                onRoleChange={(role) => {
                                    setDisabledPermissions(role.permissionIdList)
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('Other Permissions')}
                            name={'permissionIdList'}
                        >
                            <PermissionGroup
                                disabledPermission={disabledPermissions}
                                selectedPermissions={selectedPermissions}
                                onChangeSelectedPermissions={(permissions) => setSelectedPermissions(permissions)}
                            />
                        </Form.Item>
                    </div>

                    <Tabs defaultActiveKey="doctor-tab">
                        <TabPane tab={t('Doctor Information')} key="doctor-tab">
                            <Form.Item
                                label={t('TitleUnv')}
                                name={'prefix'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('Name')}
                                name={'personName'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('Middle Name')}
                                name={'personName2'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('Lastname')}
                                name={'personLastname'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('T.C.')}
                                name={'tcNo'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('Diploma Number')}
                                name={'diplomaNumber'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('Registration Code')}
                                name={'registrationCode'}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t('Doctor Sign')}
                                name={'doctorSignPicture'}
                            >
                                <Flex flexDirection={'row'} alignItems={'center'}>
                                    {
                                        signImage
                                            ? (<img width={120} height={120} id={'doctor-sign-image'} src={signImage.url}/>)
                                            : (
                                                <Flex alignItems={'center'} style={{border: '1px solid black', height: 60, width: 120, textAlign: 'center'}}>
                                                    {t('Doctor Signature Image')}
                                                </Flex>
                                            )
                                    }
                                    <Button onClick={selectImage} style={{marginLeft: 8}}>{t('Select')}</Button>
                                </Flex>
                            </Form.Item>
                        </TabPane>
                    </Tabs>

                    <Form.Item>
                        <Button htmlType={'submit'}>{t('Save')}</Button>
                    </Form.Item>
                </Form>
            </ConfigProvider>
        </>
    )
}
