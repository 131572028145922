import {Form, Input, Button, Checkbox, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import Keycloak from "../../core/service/keycloak";
import {useUser} from "../../core/context/UserContext";
import {useEffect, useState} from "react";
import {use} from "i18next";
import {useHistory} from "react-router-dom";
import {Pages} from "../../AppRouter";
import ApiService from "../../core/service/api-service";

export default function LoginForm()
{
    const {t} = useTranslation();
    const {user, setToken, isAuth} = useUser();
    const history = useHistory();
    const [error, setError] = useState('')
    const [sms, setSms] = useState({
        enabled: false,
        key: null,
        timeout: 0,
        sms_auth: false,
        mail_auth: false,
    });

    const sendCode = async (type) => {
        const res = await ApiService.Auth.SendCode({
            code_key: sms.key,
            code_type: type
        })

        if(res.success) {
            setSms({
                enabled: true,
                key: res.data?.access_token,
                timeout: 60,
                sms_auth: sms.sms_auth,
                mail_auth: sms.mail_auth,
            })
            startTimer()
        }
    }

    const startTimer = () => {
        const interval = setInterval(() => {
            setSms((prev) => {
                const nextTimeout = prev.timeout-1
                if(nextTimeout === 0) {
                    clearInterval(interval)
                }

                return {
                    ...prev,
                    timeout: prev.timeout-1
                }
            })
        }, 1000)
    }

    const signIn = async (form) => {
        if(!sms.enabled) {
            const response = await ApiService.Auth.Login(form)
            if(response.success) {
                if(response.data.sms_auth) {
                    setSms({
                        enabled: true,
                        sms_auth: response.data.sms_auth,
                        mail_auth: response.data.mail_auth,
                        timeout: 0,
                        key: response.data?.access_token
                    })
                }
                else {
                    setToken(response.data?.access_token)
                }
            }
            else {
                setError('Username or password is incorrect!')
            }
        }
        else {
            const verifyResponse = await ApiService.Auth.SmsVerify({
                password: form.code,
                code_key: sms.key
            })

            if(verifyResponse.success) {
                setToken(verifyResponse.data?.access_token)
            }
            else {
                setError(t('Verification code is wrong!'))
            }
        }
    }

    const redirectToHome = () => {
        if(isAuth())
        {
            history.push(Pages.Study.Study)
        }
        else {
            localStorage.removeItem('token')
        }
    }

    useEffect(() => {
        redirectToHome()
    }, [])

    useEffect(() => {
        redirectToHome()
    }, [user])

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={signIn}
            >
                <Form.Item
                    label={t('Username')}
                    name="username"
                    rules={[{ required: true, message: t('Please input your username!') }]}
                >
                    <Input />
                </Form.Item>

                {
                    !sms.enabled && (
                        <Form.Item
                            label={t('Password')}
                            name="password"
                            rules={[{ required: true, message: t('Please input your password!') }]}
                        >
                            <Input.Password disabled={sms.enabled}/>
                        </Form.Item>
                    )
                }

                {
                    sms.enabled && (
                        <>
                            <Form.Item
                                label={' '}
                                colon={false}
                            >
                                <div style={{width: '100%', wordWrap: 'break-word', wordBreak: 'break-word'}}>
                                    {t('You need to verify the security code to log in to your account.')}
                                </div>
                            </Form.Item>
                            <Form.Item
                                label={t('Code')}
                                name="code"
                                rules={[{required: true, message: t('Please input sms verification code!') }]}
                            >
                                <Input
                                    // type={'hidden'}
                                    addonAfter={(
                                        <div style={{display: 'flex'}}>
                                            {
                                                sms.sms_auth && (
                                                    <Button
                                                        size={'small'}
                                                        disabled={sms.timeout > 0}
                                                        onClick={() => {
                                                            sendCode('sms')
                                                        }}
                                                    >
                                                        {t('SMS')}
                                                    </Button>
                                                )
                                            }
                                            {
                                                sms.mail_auth && (
                                                    <Button
                                                        size={'small'}
                                                        disabled={sms.timeout > 0}
                                                        onClick={() => {
                                                            sendCode('mail')
                                                        }}
                                                    >
                                                        {t('Mail')}
                                                    </Button>
                                                )
                                            }
                                            <div>
                                                {sms.timeout > 0 && ` (${sms.timeout})`}
                                            </div>
                                        </div>
                                    )}
                                />
                            </Form.Item>
                            {/*<Form.Item>*/}
                            {/*    <ReCAPTCHA*/}
                            {/*        sitekey="YOUR_SITE_KEY" // Buraya Google'dan aldığınız site anahtarını koyun*/}
                            {/*        ref={recaptchaRef}*/}
                            {/*        size="invisible"*/}
                            {/*    />*/}
                            {/*</Form.Item>*/}
                        </>
                    )
                }

                <Typography.Text style={{color: '#ff0000', fontWeight: 'bold'}}>
                    {t(error)}
                </Typography.Text>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        {
                            !sms.enabled ? t('Login') : t('Send')
                        }
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
